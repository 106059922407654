<template>
  <v-card-actions class="text-right">
    <v-btn
      color="primary"
      :loading="loadingDocx"
      :disabled="disableUpload"
      @click="exportDocx"
      ><v-icon left> mdi-download </v-icon>
      Выгрузить заключение комплексной оценки (.docx)
    </v-btn>
  </v-card-actions>
</template>

<script>
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  PageOrientation,
  convertMillimetersToTwip,
  NumberFormat,
  TextRun,
  PageNumber,
  Footer,
} from 'docx'
import { saveAs } from 'file-saver'
import { createOneChapter } from '@/components/reportKO/allChapter/oneChapter'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { RED_LINE_INDENT } from '../report/docxFunctions'
import { createThirdChapter } from './allChapter/thirdChapter'
import { createFourChapter } from './allChapter/fourChapter'
import { createTwoChapter } from './allChapter/twoChapter'

export default {
  name: 'UploadKoReport',
  data: () => ({
    loadingDocx: false,
  }),
  props: {
    data: {
      type: Object,
    },
    disableUpload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['storedPlpId', 'storedPlpSigner']),
    signer() {
      return this.storedPlpSigner?.fio ?? null
    },
    signerPosition() {
      return this.storedPlpSigner?.position ?? null
    },
    mnn() {
      return this.data?.about?.mnn
    },
    ath() {
      return this.data?.about?.ath
    },
    lForma() {
      return this.data?.about?.lForma
    },
    reproducedLP() {
      return !!this.data?.about?.reproducedLP
    },
    reproducedLPText() {
      return this.data?.about?.reproducedLPtext
    },
    // аналоги без основного ЛП (сортировка по МНН - ЛФ)
    getAnalogMNN() {
      return (
        this.data?.analog?.AL?.slice(1).filter(({ anal }) => anal) ?? []
      ).sort(
        (a, b) =>
          a?.mnn?.localeCompare(b?.mnn) || a?.lForma?.localeCompare(b?.lForma)
      )
    },
    // основной + аналоги
    getListMNN() {
      if (!this.data?.analog?.AL?.[0]) return []
      return [this.data.analog.AL[0], ...this.getAnalogMNN]
    },
    getMaxAssessment() {
      return this.data?.assessment?.economic?.reduce((acc, curr) =>
        acc.resultScore > curr.resultScore ? acc : curr
      )
    },
  },
  methods: {
    ...mapActions(['CREATE_ACTION_LOG']),
    ...mapMutations(['SET_ERROR']),
    // делаем карту ссылок аналогов на КР
    prepareAnalogMap(AL, CR) {
      let counter = 1
      const outMap = {}
      const filterAL = this.getAnalogMNN
      CR?.forEach((_, index) => {
        if (filterAL?.findIndex(({ CR }) => CR[index].use) > -1) {
          outMap[index] = counter++
        }
      })
      return outMap
    },
    createAnalogData(analogData) {
      if (!analogData) return []
      // функция для объединения лекформ с одинаковым МНН для таблицы и финального отчёта
      const map = new Map()
      this.getAnalogMNN.forEach(({ mnn, ath, lForma, CR }) => {
        const key = `${ath}:${mnn}`
        const prev = map.get(key) ?? {}
        // собираем аггрегированое значение лекформы
        lForma = prev.lForma ? `${prev.lForma};\n${lForma}` : lForma

        // собираем ссылки на КР
        const linkSet = new Set(prev.link)

        CR?.forEach(({ use }, index) => {
          if (use) linkSet.add(this.analogMap[index])
        })

        map.set(key, { mnn, ath, lForma, link: [...linkSet.values()].sort() })
      })

      return [...map.values()]
    },
    createCRData(CR) {
      const usingCR = []
      CR.forEach((item, index) => {
        const num = this.analogMap[index]
        if (num) usingCR.push({ tableKey: num, ...item })
      })
      return usingCR
    },
    exportDocx() {
      this.loadingDocx = true
      let oldFirstLine = RED_LINE_INDENT.indent.firstLine
      try {
        // по дефолту красную строку на 1,25см
        RED_LINE_INDENT.indent.firstLine = convertMillimetersToTwip(12.5)

        const doc = new Document({
          sections: [
            {
              //Задаём нумерацию страниц
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              properties: {
                page: {
                  size: {
                    //Задаём альбомную ориентацию документа, размеры листа A4
                    orientation: PageOrientation.PORTRAIT,
                    height: convertMillimetersToTwip(210),
                    width: convertMillimetersToTwip(297),
                  },
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(20), //2 сантимерта
                    right: convertMillimetersToTwip(20), //2 сантиметра
                    bottom: convertMillimetersToTwip(15), //1.5 сантиметра
                    left: convertMillimetersToTwip(20), //2 сантиметра
                  },
                  pageNumbers: {
                    formatType: NumberFormat.DECIMAL,
                  },
                },
              },
              // весь документ
              children: [
                ...createOneChapter(
                  this.signer,
                  this.mnn,
                  this.ath,
                  this.lForma,
                  this.reproducedLP,
                  this.reproducedLPText,
                  !!this.data?.about?.gnvlp,
                  !!this.data?.about?.vzn14,
                  this.signerPosition,
                  this.data?.about?.nameType
                ),
                ...createTwoChapter(this.data?.cost, this.getMaxAssessment),
                ...createThirdChapter(
                  this.getListMNN,
                  this.data?.other,
                  this.data?.about,
                  this.data?.agencies
                ),
                ...createFourChapter(this.data?.assessment?.comment || ''),
              ],
            },
          ],
        })
        Packer.toBlob(doc).then(blob => {
          //ЗАПИСЫВАЕМ В ЛОГ ВЫГРУЗКУ ОТЧЁТА
          this.CREATE_ACTION_LOG({
            code: 'KO_REPORT_SAVE',
            message: `Сгенерировано заключение комплексной оценки для "${this.data.mnn}"`,
            data: {
              plp_id: this.storedPlpId,
            },
          })
          saveAs(
            blob,
            `заключение комплексной оценки для ${
              this.data.mnn ?? ''
            } от ${this.getTimestamp()}`
          )
        })

        return doc
      } catch (err) {
        this.SET_ERROR({
          head: 'Заключение',
          text: 'Ошибка формирования документа',
          err,
        })
        throw err
      } finally {
        RED_LINE_INDENT.indent.firstLine = oldFirstLine
        this.loadingDocx = false
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>
