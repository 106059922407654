import {
  DocxException,
  createContentParagraph,
  createSecondContentParagraph,
  createEmptyLine,
} from '../docxFunctions'
import { createGnvlpTable } from '../allTables/createGnvlpTable'
import { createAssessmentTable } from '../allTables/createAssessmentTable'
import {
  createParagraphWithCenter,
  createCombinationParagraph,
  createParagraph,
} from '../docxFunctions'
import {
  ITALIC_TEXT,
  BOLD_TEXT,
  DEFAULT_TEXT,
  UNDERLINED_BOLD_TEXT,
} from '../docxFunctions'

function createCombination(combination, indication, name, index) {
  const calcOffer = []
  const calcYear = ['Продолжительность терапии в год: ']
  const calcPrice = ['Стоимость ']
  const calcCombine = []

  combination.costs?.forEach(cost => {
    calcOffer.push(
      createParagraph(
        `для препарата ${cost.offerLp || '-пусто-'} ${
          cost.dosageMedicine || '-'
        }, `,
        {},
        {},
        20
      )
    )
    calcYear.push(
      `для препарата ${cost.offerLp || '-пусто-'}: ≈ ${
        cost.durationTherapy || '-'
      }. `
    )
    calcPrice.push(
      `1 мг препарата ${cost.offerLp || '-пусто-'}: ≈ ${
        cost.costOneMg || '-'
      }. `
    )
    calcCombine.push(
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: cost.offerLp ? cost.offerLp + ': ' : '-пусто-',
          size: 20,
        },
        {
          type: DEFAULT_TEXT,
          content: cost.costOnePatient[0].formula || '-',
          size: 20,
        },
      ])
    )
  })

  return [
    createCombinationParagraph([
      {
        type: UNDERLINED_BOLD_TEXT,
        content: `Расчёты: ${
          index === 0 ? '(Стоимость предлагаемого ЛП)' : '(Комбинация)'
        } ${index === 0 ? '' : index}`,
      },
    ]),
    createParagraph(
      `Режим дозирования препарата ${name || '-пусто-'} у ${
        indication || '-'
      } по инструкции (данные, использованные для расчетов) составляет: `,
      {},
      {},
      20
    ),
    ...calcOffer,
    createParagraph(calcYear.join(''), {}, {}, 20),
    createParagraph(calcPrice.join(''), {}, {}, 20),
    createEmptyLine(),
    createParagraph(
      `Расчет стоимости 1 года терапии одного пациента: ${
        combination.combinePrice || ''
      }`,
      {},
      {},
      20
    ),
    ...calcCombine,
    createEmptyLine(),
  ]
}

export function createGnvlpTables(indications, calculations) {
  const allTables = []
  const footers = []

  indications.forEach((element, idx) => {
    const combinationsParagraphs = []
    const item = calculations[idx]
    item.combinations?.forEach((comb, index) => {
      combinationsParagraphs.push(
        ...createCombination(comb, item.indication, item.name, index)
      )
    })

    allTables.push(
      createParagraphWithCenter(
        `${element.valuesIndications.join(', ') || '-'}`,
        {
          size: 24,
          bold: true,
        },
        null
      )
    )
    allTables.push(createGnvlpTable(element.tableData, element.headers))
    element.valuesFooters.forEach(item => {
      footers.push(
        createCombinationParagraph([{ type: ITALIC_TEXT, content: item }])
      )
    })
    allTables.push(...footers)
    allTables.push(createEmptyLine())
    allTables.push(...combinationsParagraphs)
    allTables.push(createEmptyLine())
  })
  return allTables
}
export function createVznTables(indications, calculations) {
  const allTables = []
  const footers = []
  indications.forEach((element, idx) => {
    const combinationsParagraphs = []
    const item = calculations[idx]
    item.combinations?.forEach((comb, index) => {
      combinationsParagraphs.push(
        ...createCombination(comb, item.indication, item.name, index)
      )
    })

    allTables.push(
      createParagraphWithCenter(
        `${element.valuesIndications.join(', ') || '-'}`,
        {
          size: 24,
          bold: true,
        },
        null
      )
    )
    allTables.push(createGnvlpTable(element.tableData, element.headers))
    element.valuesFooters.forEach(item => {
      footers.push(
        createCombinationParagraph([{ type: ITALIC_TEXT, content: item }])
      )
    })
    allTables.push(...footers)
    allTables.push(createEmptyLine())
    allTables.push(...combinationsParagraphs)
    allTables.push(createEmptyLine())
  })
  return allTables
}
/**
 * Экономические последствия
 */
export function createTwoChapter(cost, assessment) {
  const allIndications = cost?.indicationsData || []
  const gnvlpIndications = allIndications?.gnvlp || []
  const vznIndications = allIndications?.vzn14 || []

  const gnvlpCalculations = allIndications?.gnvlpCalculations || []
  const vznCalculations = allIndications?.vznCalculations || []

  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '4. Результаты оценки экономических последствий применения лекарственного препарата:'
      ),
      createEmptyLine(),
      createSecondContentParagraph(
        '4.1. расчет стоимости курса (года) лечения предлагаемым лекарственным препаратом по сравнению с терапевтически аналогичными лекарственными препаратами, обладающими доказанными клиническими, и (или) клинико-экономическими преимуществами, и (или) особенностями механизма действия, включенными в перечень жизненно необходимых и важнейших лекарственных препаратов и (или) перечень дорогостоящих лекарственных препаратов и применяющимися по тем же показаниям, что и предлагаемый лекарственный препарат:'
      ),
      createEmptyLine(),
      ...createGnvlpTables(gnvlpIndications, gnvlpCalculations),
      ...createVznTables(vznIndications, vznCalculations),
      createSecondContentParagraph(
        '4.2. результаты клинико-экономической оценки и оценки влияния на бюджеты бюджетной системы Российской Федерации (далее - бюджет) лекарственного препарата по каждому показанию с указанием кода Международной статистической классификации болезней и проблем, связанных со здоровьем, расшифровка:'
      ),
      createEmptyLine(),
      createAssessmentTable(assessment),
    ]
  } catch (err) {
    throw new DocxException(3, err.message)
  }
}
