import {
  DocxException,
  createContentParagraph,
  createSecondContentParagraph,
  createEmptyLine,
} from '../docxFunctions'
import { createConsequenceTable } from '../allTables/createConsequenceTable'
import { createScoreTable } from '../allTables/createScoreTable'
import { createAgenciesTable } from '../allTables/createAgenciesTable'

/**
 * ТРЕТЬЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ до таблицы CONSEQUENCE TABLE
 */
export function createThirdChapter(consequence, score, about, agencies) {
  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '5. Результаты изучения дополнительных последствий применения лекарственного препарата включают в себя:'
      ),
      createSecondContentParagraph(
        '5.1. сведения о государственной регистрации, об обращении, объемах государственных закупок предлагаемого лекарственного препарата, а также лекарственных препаратов, включенных в перечень жизненно необходимых и важнейших лекарственных препаратов и (или) перечень дорогостоящих лекарственных препаратов и имеющих аналогичные предлагаемым к включению лекарственным препаратам показания к применению, а также применяющихся в аналогичных клинических ситуациях, с учетом клинических рекомендаций:'
      ),
      createEmptyLine(),
      createConsequenceTable(consequence),
      createEmptyLine(),
      createSecondContentParagraph(
        '5.2. Результаты экспертизы прочих данных по лекарственному препарату:'
      ),
      createEmptyLine(),
      createScoreTable(score),
      createEmptyLine(),
      createSecondContentParagraph(
        '5.3. сведения о рекомендациях иностранных агентств по оценке медицинских технологий о целесообразности государственного финансирования в иностранном государстве предлагаемого лекарственного препарата'
      ),
      createEmptyLine(),
      createAgenciesTable(about, agencies),
    ]
  } catch (err) {
    throw new DocxException(3, err.message)
  }
}
