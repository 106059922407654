import { AlignmentType, Table, TableRow, WidthType } from 'docx'
import {
  EX_CORRESPOND,
  EX_NOT_CORRESPOND,
  EX_QLEVELS_HI,
} from '../../expAvbKei/const'
import { ntl } from '@/lib/strUtils'
import {
  BOLD_TEXT,
  createBoldParagraph,
  createCombinationParagraph,
  createParagraph,
  DEFAULT_TEXT,
  SUPERSCRIPT_TEXT,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
  createSeparateParagraphs,
} from '../docxFunctions'

/** окончание по числительному  */
function ru_ending_for_weight(num, words) {
  if (!Array.isArray(words)) return num

  //russian case cast for quantities, like: 1 ведро, 42 ведра, 27 ведер...
  if (num > 10 && num < 20) return `${ntl(num)} ${words[2] ?? words[1]}`
  const rem = num % 10
  if (rem === 0 || (rem >= 5 && rem <= 9))
    return `${ntl(num)} ${words[2] ?? words[1]}` // несколько
  if (rem >= 2 && rem <= 4) return `${ntl(num)} ${words[1]}` // два
  return `${ntl(num)} ${words[0]}` // одно
}

export const KEI = 'КЭИ'
export const AVB = 'АВБ'

export function createCellsInRow(row) {
  const isAnotation = row.name === 'Выбор препарата сравнения'
  const isCorrespond =
    row.correspond === EX_CORRESPOND && typeof row.weight === 'number'
  const weightParagraph = isCorrespond ? '+' + ntl(row.weight) : 0
  const weightTitle = typeof row.weight === 'string' ? row.weight : 0

  const nameParagraph = () => {
    if (row.name && isAnotation) {
      return createCombinationParagraph([
        {
          content: row.name,
        },
        {
          type: SUPERSCRIPT_TEXT,
          content: `*`,
        },
      ])
    } else if (row.name) {
      return createParagraph(row.name)
    } else {
      return createParagraph('')
    }
  }
  return [
    createTableCell({
      children: [createParagraph(row.order ? row.order : '')],
      width: {
        size: 2,
        type: WidthType.PERCENTAGE,
      },
    }),
    createTableCell({
      children: [nameParagraph()],
      width: {
        size: 15,
        type: WidthType.PERCENTAGE,
      },
    }),
    createTableCell({
      children: [createParagraph(weightParagraph || weightTitle)],
      width: {
        size: 7,
        type: WidthType.PERCENTAGE,
      },
    }),
    createTableCell({
      children: [
        row.correspond === EX_NOT_CORRESPOND
          ? createBoldParagraph(row.correspond)
          : createParagraph(row.correspond),
      ],
      width: {
        size: 10,
        type: WidthType.PERCENTAGE,
      },
    }),
    createTableCell({
      children: createSeparateParagraphs(row.criteria, {
        alignment: AlignmentType.JUSTIFIED,
      }),
      width: {
        size: 75,
        type: WidthType.PERCENTAGE,
      },
    }),
  ]
}

export function createTableHeaderRow() {
  return new TableRow({
    children: createCellsInRow({
      order: '№',
      name: 'Критерий',
      weight: 'Оценка (баллов)',
      correspond:
        'Оценка соответствия требованиям (соответствует/не соответствует)',
      criteria: 'Обоснование результатов оценки методологического качества',
    }),
  })
}

export function createExpTable(
  tableData,
  condition,
  mkb10,
  qLevels,
  qWeight,
  view
) {
  const notCorrespondArr = []
  try {
    //Создаём массив для создания параграфов несоответствующих пунктов
    if (view === KEI) {
      notCorrespondArr.push(
        createCombinationParagraph([
          {
            type: DEFAULT_TEXT,
            content:
              'Методологическое качество клинико-экономического исследования ',
          },
          {
            type: BOLD_TEXT,
            content: `${qLevels ? qLevels : 'пока еще не определено'}`,
          },
          {
            type: DEFAULT_TEXT,
            content: ` (${ru_ending_for_weight(Number(qWeight), [
              'балл',
              'балла',
              'баллов',
            ])})`,
          },
        ])
      )
      notCorrespondArr.push(
        createParagraph(
          `${
            qLevels !== EX_QLEVELS_HI
              ? 'КЭИ не соответствует следующим критериям:'
              : ''
          }`
        )
      )
      tableData.map(row =>
        row.correspond === EX_NOT_CORRESPOND
          ? notCorrespondArr.push(
              createParagraph(`П. ${row.order}. ${row.name}`)
            )
          : ''
      )
    } else if (view === AVB) {
      notCorrespondArr.push(
        createCombinationParagraph([
          {
            type: DEFAULT_TEXT,
            content: 'Методологическое качество анализа влияния на бюджет ',
          },
          {
            type: BOLD_TEXT,
            content: `${qLevels ? qLevels : 'пока еще не определено'}`,
          },
          {
            type: DEFAULT_TEXT,
            content: ` (${ru_ending_for_weight(Number(qWeight), [
              'балл',
              'балла',
              'баллов',
            ])})`,
          },
        ])
      )
      if (qLevels !== EX_QLEVELS_HI) {
        notCorrespondArr.push(
          createParagraph('АВБ не соответствует следующим критериям:')
        )
        tableData.map(row =>
          row.correspond === EX_NOT_CORRESPOND
            ? notCorrespondArr.push(
                createParagraph(`П. ${row.order}. ${row.name}`)
              )
            : ''
        )
      }
    }
  } catch (e) {
    throw new Error(
      `Ошибка создания несоответствующих пунктов для таблицы ${view}: ${e.message}`
    )
  }

  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        createTableHeaderRow(),
        new TableRow({
          children: [
            createTableCell({
              children: [
                createBoldParagraph(
                  `Показание, по которому ${
                    view === AVB ? 'выполнен' : 'выполнено'
                  } ${view} (код по МКБ-10, расшифровка):`
                ),
                createParagraph(`${condition || 'condition'}`),
                createParagraph(`${mkb10 || 'mkb14'}`),
              ],
              columnSpan: 5,
            }),
          ],
        }),
        ...tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
        new TableRow({
          children: [
            createTableCell({
              children: [createParagraph('Вывод')],
              columnSpan: 1,
            }),
            createTableCell({
              children: [...notCorrespondArr],
              columnSpan: 4,
            }),
          ],
        }),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createTable] Ошибка построения таблицы ${view}: ${err.message}`
    )
  }
}
