import {
  DocxException,
  createParagraph,
  createCombinationParagraph,
  createCombinationParagraphWithSpace,
  createItalicParagraphWithSpace,
  createContentParagraph,
  createEmptyLine,
  SUPERSCRIPT_TEXT,
  BOLD_TEXT,
  UNDERLINED_BOLD_TEXT,
  ITALIC_TEXT,
} from '../docxFunctions'

import { createMnnTable } from '../allTables/createMnnTable'
import { AVB, createExpTable, KEI } from '../allTables/createExpTable'
import { EX_GET_CORRESPOND } from '../../expAvbKei/const'
import { createParagraphWithCenter } from '@/components/report/docxFunctions'
import { AlignmentType } from 'docx'
/**
 Создания параграфа-аннотаций к таблице АВБ.
 */
function createAvbAnnotationParagraph() {
  return [
    createItalicParagraphWithSpace('Примечание: '),
    createItalicParagraphWithSpace(
      'Для вынесения заключения о высоком методологическом качестве исследования с использованием анализа влияния лекарственного препарата на бюджет - не менее 25,5 балла при наличии математической модели, не менее 22,5 балла при отсутствии математической модели;'
    ),
    createItalicParagraphWithSpace(
      'Для вынесения заключения о среднем методологическом качестве исследования с использованием анализа влияния лекарственного препарата на бюджет - не менее 24 баллов при наличии математической модели, не менее 21 балла при отсутствии математической модели;'
    ),
    createItalicParagraphWithSpace(
      'Для вынесения заключения о низком методологическом качестве исследования с использованием анализа влияния лекарственного препарата на бюджет - менее 24 баллов при наличии математической модели, менее 21 балла при отсутствии математической модели.'
    ),
  ]
}
/**
 Создания параграфа-аннотаций к таблице КЭИ.
 */
function createKeiAnnotationParagraph() {
  return [
    createItalicParagraphWithSpace('Примечание: '),
    createCombinationParagraph([
      {
        type: SUPERSCRIPT_TEXT,
        content: `*`,
      },
      {
        content: `Выбор препарата сравнения уточнен с главным внештатным [написать специальность] [ФИО ГВС] в соответствии с полученным письмом от [дата получения письма] № [номер письма]; лекарственные препараты сравнения согласно мнению главного внештатного специалиста, включенные в соответствующие перечни и применяемые в аналогичных клинических ситуациях, с учетом клинических рекомендаций: [«Название КР»] (КР [№КР]) [год утверждения КР] г.`,
        size: 24,
        type: ITALIC_TEXT,
      },
    ]),
    createEmptyLine(),
    createItalicParagraphWithSpace(
      'Для вынесения заключения о высоком методологическом качестве клинико-экономического исследования лекарственного препарата - не менее 29 баллов при наличии математической модели, не менее 26 баллов при отсутствии математической модели;'
    ),
    createItalicParagraphWithSpace(
      'Для вынесения заключения о среднем методологическом качестве клинико-экономического исследования лекарственного препарата - не менее 27 баллов при наличии математической модели, не менее 24 баллов при отсутствии математической модели;'
    ),
    createItalicParagraphWithSpace(
      'Для вынесения заключения о низком методологическом качестве клинико-экономического исследования лекарственного препарата - менее 27 баллов при наличии математической модели, менее 24 баллов при отсутствии математической модели.'
    ),
  ]
}

function createExpData(data) {
  return data.map(({ correspond, ...item }) => ({
    ...item,
    correspond: EX_GET_CORRESPOND(correspond),
  }))
}

function createAllExpTables(data, view) {
  const part = view === KEI ? '1' : '2'

  const annotationParagraph =
    view === KEI ? createKeiAnnotationParagraph : createAvbAnnotationParagraph

  const allTables = data.ka?.reduce(
    (arr, ka, i, arrData) => [
      ...arr,
      createParagraph(`4.${part}.${i + 1}`),
      createExpTable(
        createExpData(ka.elements),
        ka.condition,
        ka.mkb10,
        ka.qLevels,
        ka.qWeight || 0,
        view
      ),
      ...annotationParagraph(),
      ...(i < arrData.length - 1 ? [createEmptyLine()] : []),
    ],
    []
  )
  return allTables
}

/**
 * ВТОРАЯ ЧАСТЬ ЗАКЛЮЧЕНИЯ ЛП, КЕИ, АВБ
 */
export function createTwoChapter(itemsMNN, CR, kei, avb) {
  try {
    const superscriptParagraph = []
    CR.forEach((item, idx) => {
      superscriptParagraph.push(
        createCombinationParagraph([
          {
            type: SUPERSCRIPT_TEXT,
            content: `${CR[idx].tableKey}`,
          },
          {
            content: `Препарат включен в клинические рекомендации,${
              CR[idx].isApprovedNPS ? ' ' : ' не '
            }одобренные Научно-практическим Советом Министерства здравоохранения РФ и${
              CR[idx].isOnRubricator ? ' ' : ' не '
            }размещенные в информационной системе Министерства здравоохранения РФ «Рубрикатор клинических рекомендаций» (Клинические рекомендации «${
              CR[idx].name
            }» (КР ${CR[idx].crNumber ? CR[idx].crNumber : 'не определено'}); ${
              CR[idx].associations ? CR[idx].associations : 'не определено'
            }; ${CR[idx].year ? CR[idx].year + ' г.' : 'не определено'}).`,
            size: 22,
          },
        ])
      )
    })
    return [
      createCombinationParagraphWithSpace([
        {
          content:
            'д) информацию о лекарственных препаратах, включенных в перечни и имеющих аналогичные вносимым лекарственным препаратам показания к применению, а также применяющихся в аналогичных клинических ситуациях, с учетом клинических рекомендаций, утвержденных научно-практическим советом Министерства здравоохранения Российской Федерации:',
        },
      ]),
      ...(itemsMNN?.length
        ? [createMnnTable(itemsMNN), ...superscriptParagraph]
        : [
            createCombinationParagraphWithSpace([
              {
                type: UNDERLINED_BOLD_TEXT,
                content:
                  'лекарственные препараты с аналогичными показаниями отсутствуют;',
              },
            ]),
          ]),
      createEmptyLine(),
      createContentParagraph(
        '4. Результаты оценки методологического качества клинико-экономических исследований и исследований с использованием анализа влияния на бюджеты бюджетной системы Российской Федерации (далее - бюджет) включают:'
      ),
      createCombinationParagraphWithSpace([
        {
          content: 'а) Результаты оценки методологического качества ',
        },
        {
          type: BOLD_TEXT,
          content: 'клинико-экономического исследования: ',
        },
      ]),
      ...createAllExpTables(kei, KEI),
      createEmptyLine(),
      createCombinationParagraphWithSpace([
        {
          content:
            'б) Результаты оценки методологического качества исследований с использованием ',
        },
        {
          type: BOLD_TEXT,
          content: 'анализа влияния на бюджет: ',
        },
      ]),
      ...createAllExpTables(avb, AVB),
      createContentParagraph(
        '5. Подписи и расшифровка подписей сотрудников центра экспертизы, осуществляющих оценку методологического качества клинико-экономических исследований и исследований с использованием анализа влияния на бюджет:'
      ),
      createEmptyLine(),
      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        '(Ф.И.О. сотрудника Центра экспертизы, подпись)'
      ),

      createParagraph(
        '_________________________________________________________________________________________________________________________'
      ),
      createParagraphWithCenter(
        ' (руководитель Центра экспертизы, ФИО, подпись)'
      ),
      createParagraph('Дата__________', {
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 200,
        },
      }),
    ]
  } catch (err) {
    throw new DocxException(2, err.message)
  }
}
